function loadScript(src) {
  return new Promise((resolve, reject) => {
    let el = document.querySelector('script[src="' + src + '"]');

    if (el && el.hasAttribute('data-loaded')) {
      resolve(el);
      return;
    }

    el = document.createElement('script');
    el.type = 'text/javascript';
    el.async = true;
    el.src = src;

    el.addEventListener('error', reject);
    el.addEventListener('abort', reject);
    el.addEventListener('load', function loadScriptHandler() {
      el.setAttribute('data-loaded', true);
      resolve(el);
    });

    document.head.appendChild(el);
  });
}

export default {
  loadScript
};
