<template>
  <v-container>
    <v-row justify="center" align="center" class="mt-5">
      <v-col cols="12">
        <h1 class="text-h5 text-center mb-3" v-if="!failure">
          {{ $t('store.redirectingToStripe') }}
        </h1>
        <h1 class="text-h5 text-center mb-3" v-else>
          {{ $t('store.errorWithStripe') }}
        </h1>
      </v-col>
      <v-btn
        v-if="failure"
        color="app-blue"
        :dark="failure"
        large
        to="/dashboard"
      >
        {{ $t('common.goToDashboard') }}
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import scriptsLoaderService from '../../services/shared/scripts.loader.service';

export default {
  name: 'stripe-payment',
  data() {
    return {
      failure: false
    };
  },
  async created() {
    await scriptsLoaderService.loadScript('https://js.stripe.com/v3/');
    this.handleStripePayment();
  },
  methods: {
    async handleStripePayment() {
      const { sessionId } = this.$route.query;
      const stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);

      try {
        await stripe.redirectToCheckout({ sessionId });
      } catch (err) {
        this.failure = true;
        console.error(err);
      }
    }
  }
};
</script>
